import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexModules from "../components/index-modules"

const IndexPage = ({data}) => (
  <Layout>
    <SEO
      title="康體通羽毛球場實時資訊"
      lang="zh-hk"
    />
    <IndexModules />
  </Layout>
)

export default IndexPage
